import { Container, Fade } from "@mui/material"


export const AboutPage = () => {

    return (
        <div style={{paddingTop: '70px'}} >
            <div>
                <Fade in={true}>
                    <div>
                        <Container style={{minHeight: '75px', display: 'flex', alignItems: 'center'}}>
                            <h1>About Us</h1>
                        </Container>
                        <div className="gradient" />
                    </div>
                </Fade>
            </div>

            <div>
                <div>
                    <Fade in={true}>
                        <div>
                            <Container>
                                <div style={{backgroundColor: '#fff3', padding: '10px', minHeight: '200px', display: 'flex', alignItems: 'center'}}>
                                    <p style={{lineHeight: '2rem'}}>Abisati is the first technology spinout from Endevica Bio, an Illinois-based peptide engineering and discovery group. Abisati is taking Endevica’s exciting obesity developments into a focused structure for investment and a rapid move towards public market valuations. We expect the company will build the structure and data necessary to be ready for public markets in the first half of 2026.</p>
                                </div>
                            </Container>
                            <div className="gradientBack" />
                        </div>
                    </Fade>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', minHeight: '300px', alignItems: 'center'}}>
                <img src={process.env.PUBLIC_URL + '/images/vector/abi-icon.webp'} style={{maxWidth: '250px'}} alt="Abisati" />
            </div>
        </div>
    )
}