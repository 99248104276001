import { Alert, Button, Container, Fade, Grid2, Snackbar, TextField } from "@mui/material"
import { useState } from "react"

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const ContactComponent = () => {
    // Variables
    const [ name, setName ] = useState("")
    const changeName = (e) => setName(e.target.value)
    const [ email, setEmail ] = useState("")
    const changeEmail = (e) => setEmail(e.target.value)
    const [ message, setMessage ] = useState("")
    const changeMessage = (e) => setMessage(e.target.value)

    // Severity
    const [ severity, changeSeverity ] = useState("success")
    const [ alertMessage, changeAlertMessage ] = useState("")

    // Send Message
    const sendContactInfo = () => {

        if (name === "") {
            // Check Name 
            changeAlertMessage("Name Required")
            changeSeverity("error")
            openSnackbar()
            return 
        } else if (!validateEmail(email)) {
            // Check email address validity 
            changeAlertMessage("Email Address Invalid")
            changeSeverity("error")
            openSnackbar()
            return 
        } else if (message === "") {
            changeAlertMessage("Message Required")
            changeSeverity("error")
            openSnackbar()
            return
        }

        // send message
        fetch('/api/contact', {
            body: JSON.stringify({
                email: email,
                name: name,
                message: message,
            }),
            method: "POST",
        }).then(res => {
            changeAlertMessage("Successfully Sent Contact Information")
            changeSeverity("success")
            openSnackbar()
            setName("")
            setMessage("")
            setEmail("")
        }).catch(e => {
            changeAlertMessage("Error Sending Contact Information")
            changeSeverity("error")
            openSnackbar()
        })
        
    }

    // snackbar 
    const [ snackbarOpen, setSnackbarOpen ] = useState(false)
    const openSnackbar = () => {
        setSnackbarOpen(true)
    }
    const closeSnackbar = () => setSnackbarOpen(false)

    return (
        <div style={{backgroundColor: '#fff', minHeight: '125px', borderTop: '90px solid #498bc4'}}>
            <Fade in={true}>
                <Container>
                    <div style={{paddingTop: '25px'}}>
                        <Grid2 container>
                            <Grid2 size={{xs: 12, md: 6}} style={{display: 'flex', alignItems: 'center'}}>
                                <h2 style={{color: '#333'}}>Looking for more information?</h2>
                            </Grid2> 
                            <Grid2 size={{xs: 12, md: 6}}>
                                <div style={{
                                    display: 'flex', 
                                    backgroundColor: '#eee', 
                                    borderRadius: '20px', 
                                    flexDirection: 'column', 
                                    maxWidth: '500px',
                                    margin: '20px auto 40px auto',
                                    border: '1px solid #bbb',
                                    padding: '20px', paddingBottom: '50px'}}>
                                    <h2 style={{textAlign: 'center', color: '#333'}}>Contact Us</h2>
                                    <div style={{padding: '10px'}}>
                                        <TextField label="Name" fullWidth 
                                            value={name}
                                            onChange={changeName}
                                            slotProps={{
                                                input: {
                                                    style: {
                                                        backgroundColor: '#fff'
                                                    }
                                                }
                                            }}
                                            />
                                    </div>
                                    <div style={{padding: '10px'}}>
                                        <TextField 
                                            value={email}
                                            onChange={changeEmail}
                                            slotProps={{
                                                input: {
                                                    style: {
                                                        backgroundColor: '#fff'
                                                    }
                                                }
                                            }}
                                            label="Email" fullWidth />
                                    </div>
                                    <div style={{padding: '10px'}}>
                                        <TextField 
                                            value={message}
                                            onChange={changeMessage}
                                            slotProps={{
                                                input: {
                                                    style: {
                                                        backgroundColor: '#fff'
                                                    }
                                                }
                                            }} label="Message" rows={3} multiline fullWidth />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                                        <Button variant="contained" onClick={sendContactInfo} style={{width: '200px'}}>Submit</Button>
                                    </div>
                                </div>
                                
                            </Grid2>
                        </Grid2>
                    </div>
                </Container>
            </Fade>

            <div>
                <Snackbar
                    open={snackbarOpen}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert severity={severity}>{alertMessage}</Alert>
                </Snackbar>
            </div>
        </div>
    )
}