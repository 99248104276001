import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';

export const AppBarComponent = () => {
    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [ animSpin, setAnimSpin ] = useState(false)
    const handleDrawerOpen = () => setDrawerOpen(true)
    const handleDrawerClose = () => {
        setAnimSpin(true)
        setDrawerOpen(false)
    }

    const closeAndScroll = () => {
        handleDrawerClose();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <AppBar position="fixed" style={{backgroundColor: '#fff', borderBottom: '1px solid #999'}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <Typography
                variant="h6"
                noWrap
                component={Link}
                onClick={closeAndScroll}
                to="/"
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'sans-serif',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                <img src={process.env.PUBLIC_URL + '/images/vector/abi-horisontal.webp'} alt="Abisati" style={{maxWidth: '300px'}}/>
            </Typography>
            <Typography
                variant="h5"
                noWrap
                component={Link}
                onClick={closeAndScroll}
                to="/"
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'sans-serif',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                <img src={process.env.PUBLIC_URL + '/images/vector/abi-horisontal.webp'} alt="Abisati" style={{maxWidth: '200px'}} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                <Button
                    LinkComponent={Link}    
                    to="/" 
                    key={'home'}
                    onClick={closeAndScroll}
                    sx={{ my: 2, color: 'white', display: 'block', minWidth: '125px', textAlign: 'center',ml: '5px', mr: '5px' }}
                    style={{border: '1px solid #ccc', backgroundColor: '#498bc4'}}
                    className='buttonHover'
                >
                    Home
                </Button>
                <Button
                    LinkComponent={Link}    
                    to="/about-us" 
                    key={'about-us'}
                    onClick={closeAndScroll}
                    sx={{ my: 2, color: 'white', display: 'block', minWidth: '125px', textAlign: 'center', ml: '5px', mr: '5px' }}
                    style={{border: '1px solid #ccc', backgroundColor: '#498bc4'}}
                    className='buttonHover'
                >
                    About
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <IconButton
                size="large"
                aria-label="navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
                color="inherit"
                style={{border: '1px solid #ccc', margin: '5px'}}
                >
                    <MenuIcon style={{color: '#111'}}/>
                </IconButton>
                <Drawer
                    anchor='right'
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    >
                    <Box sx={{ width: '100vw', minHeight: '100%',  }} role="presentation">
                        <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderBottom: '1px solid #498bc4'}}>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/images/abisati-logo-trans.webp'} alt="Abisati" style={{maxWidth: '250px'}} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <IconButton style={{
                                    backgroundColor: '#fff',
                                    border:'1px solid #999', margin: '5px 15px 5px 5px'}} className={animSpin ? 'animSpin' : ''} onClick={handleDrawerClose}>
                                    <CloseIcon style={{color: '#498bc4'}} />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Link to="/" style={{textDecoration: 'none'}} onClick={closeAndScroll}>
                                <div className='mobileHover' 
                                    style={{borderRadius: '10px', minHeight: '50px', alignItems: 'center',  padding: '10px', margin: '15px', color: '#fff', display: 'flex', justifyContent: 'center'}}>
                                    <HomeIcon />&nbsp;
                                    <span style={{fontWeight: 'bold'}}>Home</span>
                                </div>
                            </Link>
                            <Link
                                to="/about-us" style={{textDecoration: 'none'}} onClick={closeAndScroll}>
                                <div className='mobileHover' 
                                    style={{borderRadius: '10px', minHeight: '50px', alignItems: 'center', padding: '10px', margin: '15px', color: '#fff', display: 'flex', justifyContent: 'center'}}>
                                    <InfoIcon />&nbsp;
                                    <span style={{fontWeight: 'bold'}}>About</span>
                                </div>
                            </Link>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                            <img src={process.env.PUBLIC_URL + '/images/vector/abi-icon.webp'} alt="Abisati" style={{maxWidth: '250px'}} />
                        </div>
                    </Box>
                </Drawer>
            </Box>
            </Toolbar>
        </Container>
        </AppBar>
    );
}