import { Box, Container, Fade, Grid2, IconButton, Modal, Tooltip } from "@mui/material"
import '../Animation.css'
import { useEffect, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";

export const HomePage = () => {

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        border: '1px solid #333',
        boxShadow: 24,
        p: 4,
    };

    const [ modalOpen, setModalOpen ] = useState(false)
    const [ modalImage, setModalImage ] = useState('')
    const openModal = () => setModalOpen(true)
    const closeModal = () => setModalOpen(false)

    const triggerOpenModal = (image) => {
        setModalImage(image)
        openModal()
    }

    // on scroll 
    const allBgColors = ["#f7cc2c", "#6e6b6f", "#fff", "#498bc4"]
    const [ bgColor, setBgColor ] = useState(allBgColors[0])
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition < 500) {
                setBgColor(allBgColors[0]);
            } else if (scrollPosition < 1000) {
                setBgColor(allBgColors[1]);
            } else if (scrollPosition < 1500) {
                setBgColor(allBgColors[2]);
            } else {
                setBgColor(allBgColors[3]);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="bgAnim" style={{paddingTop: '70px'}}>
            <Fade in={true}>
                <div>
                    <Container style={{minHeight: '550px', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img 
                                src={process.env.PUBLIC_URL + '/images/abisati-logo-trans.webp'} style={{maxWidth: '100%'}} alt="Abisati" />
                        </div>
                    </Container>

                    <div className="sep" style={{display: 'flex', borderTop: '10px solid #498bc4', borderTopLeftRadius: '20px', backgroundColor: bgColor, transition: '3s'}}>
                        <Container style={{backgroundColor: '#fff', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px'}}>
                            <div style={{minHeight: '550px', lineHeight: '2rem', paddingTop: '50px', paddingBottom: '100px', paddingLeft: '15px'}}>
                                <Grid2 container>
                                    <Grid2 size={{xs:12, md:12}}>
                                    <p>
                                    Abisati is focused on addressing the obesity epidemic in the U.S. More than 100 million adults are obese and more than 22 million have severe obesity, <a style={{color: '#498bc4', fontWeight: 'bold'}} href="https://www.cdc.gov/obesity/php/data-research/adult-obesity-facts.html#:~:text=Many%20U.S.%20adults%20have%20obesity,million%20adults%20have%20severe%20obesity." target="_blank" rel="noreferrer" >according</a> to the most recently available data from the Centers for Disease Control and Prevention.
                                    <br /><br />
                                    Obesity creates risks for heart disease, type 2 diabetes, and cancers. About <a style={{color: '#498bc4', fontWeight: 'bold'}} href="https://stacks.cdc.gov/view/cdc/106273" target="_blank" rel="noreferrer">58%</a> of American adults with obesity also have high blood pressure and that 23% with obesity have diabetes.
                                    <br /><br />
                                    The costs of obesity are significant. It accounts for about $173 billion in medical expenditures in 2019 dollars.
                                    <br /><br />
                                    GLP-1 agonists are playing a significant role in the fight against obesity by serving as a primary medication option for weight loss. The drugs reduce appetite and promote satiety, leading to decreased food intake and weight reduction. According to <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9877131/#:~:text=In%20randomized%20weight%20loss%20trials,12" style={{color: '#498bc4', fontWeight: 'bold'}} target="_blank" rel="noreferrer">studies</a>, people taking GLP-1s typically lose between 10% and 15% of their body weight, with some newer GLP-1s potentially leading to even higher weight loss percentages.
                                    <br /><br />
                                    </p>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container>
                                    <Grid2 size={{xs: 12, md: 6}}>
                                        <div>
                                        But we know there are drawbacks to GLP-1s. Weight loss can slow or stop over time as the body adjusts to new calorie intake levels and attempts to maintain a set weight. Patients can also experience weight gains after discontinuing use of GLP-1s. The drugs also can cause nausea, vomiting, diarrhea, and constipation.
                                        <br/><br/>
                                        A 2024 analysis of pharmacy claims found that only 15% of those prescribed GLP-1s for weight loss continued to take the drugs two years later. That is likely because of cost and tolerability.
                                        <br /><br />
                                        </div>
                                    </Grid2>
                                    <Grid2 size={{xs:12, md:6}} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                                        <img 
                                            onClick={() => triggerOpenModal(process.env.PUBLIC_URL + '/images/data/FoodIntakeReduction.png')}
                                            className='dynImage borderAnimation' src={process.env.PUBLIC_URL + '/images/data/FoodIntakeReduction.png'} alt="Food Intake Reduction"  />
                                    </Grid2>
                                </Grid2>
                                <Grid2 container>
                                    <Grid2 size={{xs:12, md:12}}>
                                    All of these factors suggest that alternatives are needed.
                                    <br /><br />
                                    </Grid2> 
                                    <Grid2 size={{xs:12, md:6}}>
                        
                                    <div style={{textAlign: 'center', padding: '20px', maxWidth: '100%'}}>
                                        <img 
                                            onClick={() => triggerOpenModal(process.env.PUBLIC_URL + '/images/data/WeightLossEfficacy.png')}
                                            className='dynImage borderAnimation' src={process.env.PUBLIC_URL + '/images/data/WeightLossEfficacy.png'}  
                                            alt="Weight Loss Efficacy" />
                                        <div style={{fontSize: '0.85rem'}}>* NHP - Nonhuman Primate</div>
                                    </div>
                                    </Grid2>
                                    <Grid2 size={{xs:12, md:6}} style={{display: 'flex', alignItems: 'center'}}>
                                    Our approach is unique. We have developed melanocortin-4 receptor agonists that have been shown in rats to be just as effective at weight loss as GLP-1s. But there's a significant benefit to our drug candidate, 710GO: rat and primate studies show there is no weight gain after discontinuing use of the drug.
                                    </Grid2>
                                    <Grid2 size={{xs:12, md:12}}>
                                    <br />

                                    The orally-dosed molecule resulted in weight loss weight loss of 7.5% in 5 weeks in diet-induced obesity nonhuman primate models. The cardiac-safe molecule shows extremely clean safety signals across all safety panels. The molecule also combines well with semaglutide in combination trials, allowing lower doses of the GLP-1 and better weight loss.
                                    Abisati is scheduled to start a 28-day oral toxicity study in November of 2024, phase 1 studies by the end of the second quarter in 2025 and have topline human results available in late 2025. 
                                    </Grid2>
                                    <Grid2 size={{xs: 12, md: 12}}>
                                        <div style={{display: 'flex', justifyContent:'center', padding: '20px'}}>
                                            <img 
                                                onClick={() => triggerOpenModal(process.env.PUBLIC_URL + '/images/data/ReboundResistance.png')}
                                                className='dynImage borderAnimation' src={process.env.PUBLIC_URL + '/images/data/ReboundResistance.png'}  
                                                alt="Weight Loss Efficacy" />
                                        </div>
                                    </Grid2>
                                </Grid2>
                            </div>
                        </Container>
                    </div>

                    { /** Image Modal */}
                    <Modal
                        open={modalOpen}
                        onClose={closeModal}>
                        <Fade in={modalOpen}>
                            <div>
                            <Box sx={modalStyle} className='modalGrow' style={{padding: '0px'}}>
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', backgroundColor: '#498bc4', padding: '5px'}}>
                                        <Tooltip title="Close">
                                        <IconButton onClick={closeModal} style={{border: '1px solid #bbb', marginRight: '10px', backgroundColor: '#fff'}}>
                                            <CloseOutlined />
                                        </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div style={{padding: '10px'}}>
                                        <img src={modalImage} alt="modal" style={{maxWidth: '100%', marginTop: '15px'}} />
                                    </div>
                                </div>
                            </Box>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </Fade>
        </div>
    )
}
