import { Button, Container, Grid2 } from "@mui/material"
import { Link } from "react-router-dom"
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const FooterComponent = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className='blueBg footerClass'>
            <Container style={{ paddingBottom: '20px'}}>
                <Grid2 container>
                    <Grid2 size={{xs: 12, md: 6}} style={{paddingTop: '20px'}}>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Button className='footerHover' LinkComponent={Link} to="/" onClick={scrollToTop} style={{marginBottom: '5px', color: '#fff', border: '1px solid #fff6', minWidth: '150px'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><HomeIcon />&nbsp; Home</div>
                            </Button>
                            <Button className='footerHover' LinkComponent={Link} to="/about-us" onClick={scrollToTop} style={{color: '#fff', border: '1px solid #fff6', minWidth: '150px'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><InfoIcon />&nbsp; About</div>
                            </Button>
                        </div>

                    </Grid2>
                    <Grid2 size={{xs: 12, md: 6}} style={{padding: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
                            <LocationOnIcon />
                        </div>
                        <div>
                            <div style={{padding: '5px'}}>1935 Techny Rd, Suite 14</div>
                            <div style={{padding: '5px'}}>Northbrook, IL 60062 USA</div>
                        </div>
                    </Grid2>
                </Grid2>
            </Container>

            <div style={{borderTop: '1px solid #fff7', paddingBottom: '20px', backgroundColor: '#6e6b6f'}}>
                <Container>
                    <Grid2 container>
                        <Grid2 size={{xs: 12, sm: 12}}>
                            <div style={{
                                paddingTop:'20px',
                                textAlign: 'center', fontSize: '0.8rem'}}>Copyright &copy; {new Date().getFullYear()} Abisati - All Rights Reserved</div>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>
        </div>
    )
}