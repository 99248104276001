import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { HomePage } from './pages/Home';
import { FooterComponent } from './components/Footer';
import { AppBarComponent } from './components/Navbar';
import { AboutPage } from './pages/About';
import { ContactComponent } from './components/Contact';

function App() {
  return (
    <div>
      <BrowserRouter>
        <AppBarComponent />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
        </Routes>
        <ContactComponent />
        <FooterComponent />
      </BrowserRouter>  
    </div>
  );
}

export default App;
